@import '@arco-themes/react-arco-pro/variables.less';

.message-box {
  width: 400px;
  max-height: 800px;
  background-color: var(--color-bg-popup);
  border: 1px solid var(--color-border-2);
  box-shadow: @shadow2-down;
  border-radius: @border-radius-medium;

  :global(.arco-tabs-header-nav) {
    padding: 8px 16px;
    border-bottom: 1px solid var(--color-border-2);
  }

  :global(.arco-list-item-meta) {
    align-items: flex-start;
  }

  :global(.arco-list-item-meta-content) {
    width: 100%;
  }

  :global(.arco-tabs-content) {
    padding-top: 0;
  }
}

.message-title {
  display: flex;
  justify-content: space-between;
}

.footer {
  display: flex;
}

.footer-item {
  display: flex;
  justify-content: center;
  width: 50%;

  &:first-child {
    border-right: 1px solid var(--color-border-2);
  }
}
